<template>
  <form action="/?page_id=2145#wpcf7-f6-p2145-o1" method="post" class="wpcf7-form" novalidate="novalidate">
  <div style="display: none;">
  <input type="hidden" name="_wpcf7" value="6" />
  <input type="hidden" name="_wpcf7_version" value="5.0.1" />
  <input type="hidden" name="_wpcf7_locale" value="ru_RU" />
  <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f6-p2145-o1" />
  <input type="hidden" name="_wpcf7_container_post" value="2145" />
  </div>
  <p>Ваше имя (обязательно)<br />
      <span class="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" /></span> </p>
  <p>Ваш e-mail (обязательно)<br />
      <span class="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" /></span> </p>
  <p>Тема сообщения<br />
      <span class="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" value="" size="40" class="wpcf7-form-control wpcf7-text" aria-invalid="false" /></span> </p>
  <p>Сообщение<br />
      <span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false"></textarea></span> </p>
  <p><input type="submit" value="Отправить" class="wpcf7-form-control wpcf7-submit" /></p>
  <div class="wpcf7-response-output wpcf7-display-none"></div></form>
</template>

<script>
export default {
name : "contactform1"



}


</script>
