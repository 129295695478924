<template>
<div>
  <div id="main" class="site-main">

  <div id="main-content" class="main-content">


  <div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-icon"><div class="sc-icon sc-icon-size-medium angle-90deg sc-icon-shape-circle" style="background-color: #ffffff;border: 2px solid #ffffff;"><span class="sc-icon-half-1" style="color: #ffa188;"><span class="back-angle">&#xe691;</span></span><span class="sc-icon-half-2" style="color: #ff7070;"><span class="back-angle">&#xe691;</span></span></div></div><div class="page-title-title"><h1 style="color: #4c5867;">  Контакты</h1></div></div></div>




  <div class="block-content">
  	<div class="container">
  		<div class="panel row">

  			<div class="panel-center col-xs-12">
  				<article id="post-2145" class="post-2145 page type-page status-publish hentry">


  					<div class="entry-content post-content">
  												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider" style="margin-top: -47px;"></div><div class="fullwidth-block clearfix styled-marker-top" style="background-color: #eaeff2;background-position: center top;">
  <div class="wpb_gmaps_widget wpb_content_element">
  		<div class="wpb_wrapper">
  		<div class="wpb_map_wraper">
  			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1942.2126548917302!2d37.65873101409344!3d55.78596083790777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b5358f510ef233%3A0xe484b375b5e86c!2z0JLQtdGA0YXQvdGP0Y8g0JrRgNCw0YHQvdC-0YHQtdC70YzRgdC60LDRjyDRg9C7LiwgMywg0JzQvtGB0LrQstCwLCAxMDcxNDA!5e1!3m2!1sru!2sru!4v1465390882619" width="600" height="480" frameborder="0" style="border:0" allowfullscreen></iframe>		</div>
  	</div></div></div><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 57px;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div>
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<h3 style="text-align: center;">КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>

  		</div>
  	</div>
  <div class="sc-textbox rounded-corners"  style="border: 1px solid #d2dae1;"><div class="sc-textbox-content" style="background-position: center top;"><div class="vc_row wpb_row vc_inner vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe618;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe618;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Адрес</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p data-select-like-a-boss="1">Москва, 107140, ул. Верхняя Красносельская, д. 3, стр. 1, 6 этаж</p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 27px;border-color: #d2dae1;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="#d2dae1" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe620;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe620;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Email</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p><a href="mailto:info@aorst.tu">info@aorst.ru</a></p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe63d;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe63d;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Сайт</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p><a href="http://aorst.ru/">www.aorst.ru</a></p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 27px;border-color: #d2dae1;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="#d2dae1" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe699;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe699;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Телефон</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>8 495 7-801-802</p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe675;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe675;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Факс</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>8 495 7-801-802</p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 27px;border-color: #d2dae1;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="#d2dae1" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div><div class="sc-icon-with-text sc-icon-with-text-icon-size-small"><div class="sc-icon-with-title sc-icon-with-title-icon-size-small"><div class="sc-icon-with-title-icon"><div class="sc-icon sc-icon-size-small  sc-icon-shape-circle" style="background-color: #f1f5f8;border: 2px solid #f1f5f8;"><span class="sc-icon-half-1" style="color: #7acdc5;"><span class="back-angle">&#xe628;</span></span><span class="sc-icon-half-2" style="color: #7acdc5;"><span class="back-angle">&#xe628;</span></span></div></div><div class="sc-iconed-title"><h6 style="">Время работы</h6></div></div><div class="sc-icon-with-text-content"><div class="sc-icon-with-text-empty"></div><div class="sc-icon-with-text-text">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>Понедельник-Пятница 10.00-20.00</p>

  		</div>
  	</div>
  </div></div><div class="clearboth"></div></div></div></div></div></div></div></div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 30px;"></div>
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<h3 style="text-align: center;">ОТПРАВИТЬ СООБЩЕНИЕ</h3>

  		</div>
  	</div>
  </div></div></div></div><div role="form" class="wpcf7" id="wpcf7-f6-p2145-o1" lang="ru-RU" dir="ltr">
  <div class="screen-reader-response"></div>
<contactform1 />
  </div></p>
  					</div><!-- .entry-content -->







  				</article><!-- #post-## -->

  			</div>


  		</div>


  	</div>
  </div>



  </div><!-- #main-content -->



  		</div><!-- #main -->

</div>

</template>

<script>
import {rollup} from "@/lib/utils.js"
import contactform1 from "@/components/contacts/contactform1.vue"
export default {
name : "Contacts",
components : {contactform1},
mounted() {
rollup()

}

}
</script>
